<template>
  <div class="login-container container">
    <div class="row justify-content-center">
      <div class="col-md-6 col-lg-4">
        <div class="card p-4">
          <h2 class="text-center mb-4">
            Iniciar Sesión
          </h2>
          <form @submit.prevent="submitForm">
            <div class="mb-3">
              <label
                for="email"
                class="form-label"
              >Correo Electrónico</label>
              <input
                id="email"
                v-model="email"
                type="email"
                class="form-control"
                required
                placeholder="Introduce tu correo"
              >
            </div>
  
            <div class="mb-3">
              <label
                for="password"
                class="form-label"
              >Contraseña</label>
              <input
                id="password"
                v-model="password"
                type="password"
                class="form-control"
                required
                placeholder="Introduce tu contraseña"
              >
            </div>
  
            <button
              type="submit"
              class="btn btn-primary w-100"
            >
              Iniciar Sesión
            </button>
          </form>
  
          <p
            v-if="errorMessage"
            class="text-danger mt-3 text-center"
          >
            {{ errorMessage }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
  export default {
    data() {
      return {
        email: '',
        password: '',
        errorMessage: ''
      };
    },
    methods: {
      submitForm() {
        if (this.email && this.password) {
          // Aquí puedes agregar la lógica para autenticar al usuario
          console.log("Formulario enviado", this.email, this.password);
  
          // Ejemplo de validación simple (puedes conectar con una API):
          if (this.email === "test@test.com" && this.password === "123456") {
            this.errorMessage = "";
            alert("Inicio de sesión exitoso!");
            // Redirigir a otra página o acción tras el login
          } else {
            this.errorMessage = "Correo o contraseña incorrectos";
          }
        }
      }
    }
  };
  </script>
  
  <style scoped>
  .login-container {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  
  .card {
    background-color: rgba(0, 0, 0, 0.7); /* Fondo negro con transparencia */
    color: white;
    border-radius: 8px;
  }
  
  h2 {
    color: #f58220; /* Color del título */
  }
  
  .form-label {
    color: white;
  }
  
  .btn-primary {
    background-color: #f58220;
    border-color: #f58220;
  }
  
  .btn-primary:hover {
    background-color: #e67300;
    border-color: #e67300;
  }
  </style>
  