<template>
  <div class="contenedor-principal">
    <!-- Contenedor Bienvenida -->
    <div class="bienvenida-container">
      <div class="overlay">
        <div class="tex1">
          Código de Vestimenta
        </div>
      </div>
      <div class="text3">
        <br> Les compartimos algunas sugerencias de Pinterest <br>
      </div>
      <!-- Segundo Overlay debajo del primero -->
      <div
        ref="bienvenidaRef"
        class="overlay2"
      >
        <!-- Contenedor del collage -->
        <div class="collage">
          <div class="image-item">
            <!-- Primer iframe de Pinterest -->
            <iframe
              src="https://assets.pinterest.com/ext/embed.html?id=11751649021788337"
              height="582"
              width="345"
              frameborder="0"
              scrolling="no"
              class="collage-image"
            />
          </div>
          <div class="image-item">
            <!-- Segundo iframe de Pinterest -->
            <iframe
              src="https://assets.pinterest.com/ext/embed.html?id=2603712280880201"
              height="618"
              width="345"
              frameborder="0"
              scrolling="no"
              class="collage-image"
            />
          </div>
        </div>
      </div>

      <div class="text2">
        <br>  Formal <br>
      </div>
      <div class="text4">
        <br>  No vestido corto <br>
      </div>
    <!--
<img
  :src="separadorImg"
  alt="Separador"
  class="separador-img"
/>
-->
    </div>
  </div>
</template>

<script>
import { onMounted, ref, nextTick } from 'vue';
import separadorImg from '@/assets/separador1.png';

export default {
  name: 'BienvenidaPage',
  setup() {
    const bienvenidaRef = ref(null);

    onMounted(async () => {
      await nextTick(); // Asegúrate de que el DOM esté completamente actualizado
    });

    return { bienvenidaRef, separadorImg };
  }
};
</script>

<style scoped>
/* Contenedor Bienvenida */
.bienvenida-container {
  background-color: rgba(48, 46, 46, 0.8); /* Fondo blanco con transparencia */
  border-radius: 15px; /* Bordes redondeados */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Sombra para efecto de elevación */
  padding: 20px; /* Espaciado interno */
  max-width: 800px; /* Ancho máximo para parecer una tarjeta */
  width: 90%; /* Ancho responsivo */
  margin: 20px auto; /* Centrado horizontal */
  margin: 20px auto 20px 20px; /* Reduce el margen izquierdo a 10px */

  z-index: 2; /* Asegúrate de que esté por encima de otros elementos */
  backdrop-filter: blur(10px); /* Efecto de desenfoque detrás de la tarjeta */
}
.contenedor-principal{
  position: relative;
  width: 100%;
  padding-top: 20px;
  padding-bottom: 30px;
  margin: 0;
  background-color: rgba(255, 253, 253, 0.95);
  z-index: 1;
  background: linear-gradient(to bottom, rgba(13, 13, 13, 0.381) 30%, rgba(41, 41, 41, 0.078)),
url('../assets/textura-fondo.jpg');
  text-align: center;
  display: flex;
  flex-direction: column;
  background-color: rgba(243,240,235,255); /* Ruta de la imagen de fondo */
  background-size: auto; /* Mantiene el tamaño real de la imagen */
  background-position: center; /* Centra la imagen en el contenedor */
  background-repeat: no-repeat; /* Evita que la imagen se repita */
  align-items: center;

  gap: 20px;
  box-sizing: border-box; /* Asegura que el padding no agregue más ancho */
  overflow-x: hidden; /* Evita el desbordamiento horizontal */
}
/* Contenedor Overlay */
.overlay, .overlay2 {
  border-radius: 15px;
  padding: 20px;
  width: 90%; /* Se ajusta al 90% del ancho de la pantalla */
  max-width: 600px;
  margin: 0 auto;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 32px;
  color: #917087d4;
  flex-direction: row; /* Alinea los iconos en una fila horizontal */
  margin-top: -50px;
}

/* Contenedor del collage */
.collage {
  display: flex; /* Alinea las imágenes en fila */
  justify-content: space-between; /* Espacio entre las imágenes */
  gap: 10px; /* Agrega un pequeño espacio entre las imágenes */
  width: 100%;
  margin-top: 40px;
}

/* Elementos dentro del collage */
.image-item {
  flex: 1; /* Hace que cada imagen ocupe el mismo espacio */
  max-width: 45%; /* Limita el tamaño máximo de las imágenes */
}

/* Estilo de las imágenes y iframes */
.collage-image {
  width: 100%;
  height: auto;
  border-radius: 8px; /* Opcional: para dar bordes redondeados */
}

/* Estilo del texto */
.tex1 {
  font-size: 26px;
  letter-spacing: 5px; /* Espaciado entre letras */
  font-family: 'FeelingPassionate';
z-index:10;
  font-weight: 700;
  margin: 0 30px;
  background: linear-gradient(270deg, #9c916b,  #e6e1e1, #5c5640);
  background-size: 200% 100%;
  background-clip: text;
  color: transparent;
  line-height: 2.2; /* Ajusta este valor según sea necesario */
  margin-top: 80px;
  animation: animateText 5s linear infinite;
}
@font-face {
  font-family: 'FeelingPassionate';
  src: url('@/assets/fonts/passionate.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: block; /* Espera a que la fuente esté cargada antes de mostrarla */
}
@keyframes animateText {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: 0 0;
  }
}
.text2 {
  font-size: 22px;
  font-weight: 400;
  margin-top: -30px;
  color: #1a1818cd;
}
.text3 {
  font-size: 16px;
  font-weight: 400;
  margin-top: -30px;
  color: #d8d1d1cd;
}

/* Estilo del separador */
.separador-img {
  display: block;
  margin: 20px 0;
  max-width: 100%;
}

/* Animación de pulso */
@keyframes pulso {
  0%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
}

.pulso {
  animation: pulso 1.5s infinite;
}
.text4{
  font-size: 12px;
  color: #d8d1d1cd;

}
</style>
