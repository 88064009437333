<template>
  <div>
    <!-- Contenedor Bienvenida -->
    <div
      ref="bienvenidaRef"
      class="bienvenida-container"
    >
      <p class="bienvenida-text">
        {{ mensaje }}
      </p>
      <p
        v-if="mostrarMensajeFinal"
        class="textmia animate__animated animate__fadeIn"
      >
        {{ mensajeFinal }}
      </p>
    </div>
  </div>
</template>

<script>
import { onMounted, ref, nextTick } from 'vue';
import 'animate.css'; // Asegúrate de importar animate.css
import separadorImg from '@/assets/separador1.png';

export default {
  name: 'BienvenidaPage',
  setup() {
    const bienvenidaRef = ref(null);
    const mensaje = ref(""); // Primer mensaje a mostrar
    const mensajeFinal = ref(""); // Segundo mensaje a mostrar
    const text = "Que mejor cosa para dos almas humanas, que saberse unidas para toda la vida. \n \n";
    const textFinal = "Monserrat & Antonio."; // Texto final a mostrar
    let hasStartedWriting = false;
    const animationDone = ref(false);
    const mostrarMensajeFinal = ref(false);

    const escribirTexto = (texto, index, callback) => {
      if (index < texto.length) {
        mensaje.value += texto.charAt(index);
        setTimeout(() => escribirTexto(texto, index + 1, callback), 50);
      } else if (callback) {
        callback();
      }
    };

    const escribirTextoFinal = (texto, index) => {
      if (index < texto.length) {
        mensajeFinal.value += texto.charAt(index);
        setTimeout(() => escribirTextoFinal(texto, index + 1), 50);
      }
    };

    onMounted(async () => {
      await nextTick();

      if (bienvenidaRef.value) {
        const element = bienvenidaRef.value;

        const observerOptions = {
          root: null,
          rootMargin: '0px',
          threshold: [0.1]
        };

        const observerCallback = (entries) => {
          entries.forEach(entry => {
            if (entry.isIntersecting) {
              if (!animationDone.value) {
                // Solo animar el texto y no el contenedor
                if (!hasStartedWriting) {
                  setTimeout(() => {
                    escribirTexto(text, 0, () => {
                      mostrarMensajeFinal.value = true;
                      escribirTextoFinal(textFinal, 0);
                    });
                  }, 500);
                  hasStartedWriting = true;
                }

                animationDone.value = true;
              }
            } else {
              animationDone.value = false;
            }
          });
        };

        const observer = new IntersectionObserver(observerCallback, observerOptions);
        observer.observe(element);
      }
    });

    return { bienvenidaRef, mensaje, mensajeFinal, mostrarMensajeFinal, separadorImg };
  }
};
</script>

<style scoped>
/* Contenedor Bienvenida */
@font-face {
  font-family: 'Karla';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/karla/v31/qkBIXvYC6trAT55ZBi1ueQVIjQTDeJqaE0lK.woff2) format('woff2');
}

.bienvenida-container {
  position: relative;
  width: 100%;
  padding-top: 30px;
  padding-bottom: 0;
  margin: 0;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.7);
  background-size: cover;
  background-position: center;
  opacity: 1; /* Eliminada la opacidad 0 para mantener el contenedor visible */
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Estilos para el texto de bienvenida */
.bienvenida-text {
  font-family: 'Karla';
  font-size: 18px;
  color: #fffffb;
  margin: 0 30px;
  white-space: pre-line;
}

/* Para el texto final */
.textmia {
  font-family: 'FeelingPassionate';
  font-size: 34px;
  opacity: 1;
  color:  #bab179d4;
  margin-top: 10px;
  transition: opacity 0.3s ease;
}

/* Para la animación fadeIn */
.animate__animated {
  animation-fill-mode: both; /* Mantiene el estado final de la animación */
}
</style>
