<template>
  <div class="pantallas-grandes">
    <img
      class="imagen-encima animacion-logo"
      src="../assets/logoinvitacion.png"
      alt="Descripción de la imagen"
    >
    <div class="tarjeta">
      <img
        class="imagen-tarjeta"
        src="../assets/imag4.jpg"
        alt="Descripción de la imagen"
      >
      <p class="texto-inferior">
        Esta página solo es visible en dispositivos móviles.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PantallasGrandes',
};
</script>

<style>


.pantallas-grandes {
  display: flex;
  flex-direction: column;
  justify-content: center; /* Centra verticalmente */
  align-items: center; /* Centra horizontalmente */
  background-color: black;
  color: white;
  text-align: center;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.imagen-encima {
  position: absolute; /* Mantener como absolute para que no afecte el flujo */
  top: 100px; /* Ajusta este valor según sea necesario */
  transform: translate(-50%, 0); /* Centra horizontalmente */
  width: 50%;
  max-width: 200px;
  z-index: 1;
}


.animacion-logo {
  animation: girar 1.5s infinite;
}

@keyframes girar {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(10deg);
  }
  100% {
    transform: rotate(-10deg);
  }
}

.tarjeta {
  background-color: rgba(255, 255, 255, 0.1); /* Fondo semi-transparente */
  border-radius: 15px; /* Esquinas redondeadas */
  padding: 10px; /* Espaciado interno reducido */
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.5); /* Sombra para efecto de elevación */
  margin-top: 60px; /* Margen superior para separar del logo */
  width: 70%; /* Ancho de la tarjeta */
  max-width: 300px; /* Ancho máximo de la tarjeta reducido */
}

.imagen-tarjeta {
  width: 80%; /* Tamaño más pequeño para la imagen */
  max-width: 150px; /* Tamaño máximo para la imagen */
  border-radius: 10px; /* Esquinas redondeadas */
}

.texto-inferior {
  position: relative;
  z-index: 2;
  margin-top: 10px; /* Espaciado entre la imagen de la tarjeta y el texto */
  color: white; /* Color del texto */
}
</style>
