<template>
  <div>
    <!-- Contenedor Bienvenida Padres -->
    <div
      class="padres-container"
    >
      <div
        ref="texto0Ref"
        class="texto-container texto-novia"
      >
        <span class="novia">
          Miriam Monserrat 
        </span>
        <br>
        <span class="starlove">&</span>
        <br>
        <span class="nombre-novioo">Jesús Francisco Antonio </span>
      </div>

      <!-- Texto 1 -->
      <div
        ref="texto1Ref"
        class="texto-container text"
      >
        Con la bendición de DIOS <br>y de nuestros Padres
      </div>
      
      <!-- Texto 2 -->
      <div
        ref="texto2Ref"
        class="texto-container2"
      >
        <span class="titulo-novia">Padres Novia:</span> <br>
        <span class="nombre-novia">Concepción Romero Rodriguez</span> <br>
        <span class="nombre-novia">
          Felipe Reyana Arreola
        <!--  <i
            class="fas fa-cross icono"
            style="margin-left: 5px;"
          />  Ícono de la cruz alineado -->
        </span> <br>
      </div>

      <div
        ref="texto3Ref"
        class="texto-container2"
      >
        <span class="titulo-novio">Padres Novio:</span> <br>
        <span class="nombre-novio">Ma. Margarita  Jáquez Meza</span> <br>
        <span class="nombre-novio">Francisco Pulido Molina     <!-- <i
          class="fas fa-cross icono"
          style="margin-left: 5px;"
        />  Ícono de la cruz alineado -->
        </span>
      </div>
    </div>
  </div>
</template>
    
  <script>
  import { onMounted, ref, nextTick } from 'vue';
  import 'animate.css';

  export default {
    name: 'BienvenidaPadresPage',
    setup() {
      const texto1Ref = ref(null);
      const texto2Ref = ref(null);
      const texto3Ref = ref(null);
      const texto0Ref = ref(null);

      const observeElement = (element) => {
        if (element.value) {
          const observerOptions = {
            root: null,
            rootMargin: '0px',
            threshold: [0.1],
          };
  
          const observerCallback = (entries) => {
            entries.forEach((entry) => {
              if (entry.isIntersecting) {
                element.value.classList.add('animate__animated', 'animate__fadeInUp');
                element.value.style.opacity = '1';
                element.value.style.transform = 'translateY(0)';
  
                element.value.addEventListener('animationend', () => {
                  element.value.classList.remove('animate__animated', 'animate__fadeInUp');
                }, { once: true });
              } else {
                element.value.style.opacity = '0';
                element.value.style.transform = 'translateY(50px)';
              }
            });
          };
  
          const observer = new IntersectionObserver(observerCallback, observerOptions);
          observer.observe(element.value);
        }
      };
  
      onMounted(async () => {
        await nextTick();
        observeElement(texto0Ref);

        observeElement(texto1Ref);
        observeElement(texto2Ref);
        observeElement(texto3Ref);
      });
  
      return { texto0Ref, texto1Ref, texto2Ref, texto3Ref };
    },
  };
  </script>
  
  <style scoped>
@font-face {
  font-family: 'FeelingPassionate';
  src: url('@/assets/fonts/passionate.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: block; /* Espera a que la fuente esté cargada antes de mostrarla */
}


@font-face {
  font-family: 'Starlove';
  src: url('../assets/fonts/starlove.ttf') format('truetype'); /* Asegúrate de usar la extensión correcta */
  font-weight: normal;
  font-style: normal;
}
  /* Contenedor Bienvenida Padres */
  .padres-container {
    position: relative;
    width: 100%;
    height: 93vh;
    text-align: center;
    background-image: url('../assets/fondo7.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding-top: 90px;
  }
  /* Estilo para los textos */
  .texto-container {
    padding: 20px;
    margin: 10px 0;
    opacity: 0;
    font-size: 20px;
    font-family: 'Dancing Script', sans-serif;
  font-weight: 350;    color: #333;
    border-radius: 10px;
    z-index: 2;
    width: 90%;
    
    transform: translateY(50px);
  }

  .novia {
    
    font-size: 28px;
    font-family: 'Dancing Script', sans-serif;
  font-weight: 350;    color: #333;
}
  
  .texto-container.animate__animated {
    transform: translateY(0);
    opacity: 1;
  }
  



/* Estilo para la palabra Novia */
.titulo-novia {
  font-size: 14px;

  color: #454444df; /* Cambia el color según lo desees */
  font-family: 'Roboto';

  
}

/* Estilo para la palabra Novio */
.titulo-novio {
  font-size: 14px;
  color: #454444df; /* Cambia el color según lo desees */
  font-family: 'Roboto';

}
.text{
  margin-top: -20px;
  font-size: 18px;
  letter-spacing: 0.5px; /* Ajusta este valor según el espaciado que desees */
  color: #9c916b !important; 

  font-family: "Oswald", Sans-serif;
  font-weight: 350;    

}

/* Estilo para los nombres de los novios */
.nombre-novia{
  font-family: "Oswald", Sans-serif;
  font-weight: 200;
  font-weight: 350;    color: #333333d4;
  font-size: 12px;
}

.nombre-novio {
  font-family: "Oswald", Sans-serif;
  font-weight: 200;
  font-weight: 350;    color: #333333d4;
  font-size: 12px;


}

.starlove {
  margin-top:-30px;
  font-family: 'starlove', cursive; /* Cambia 'cursive' por la fuente por defecto si deseas */
  font-size: 44px; /* Ajusta el tamaño según lo necesites */
  color: #93806d; /* Ajusta el color según tu preferencia */
  top:-20px;
}
/*cruz*/
.icono {
  color: #726c55 !important; /* Color de la cruz */
  font-size: 8px; /* Ajusta este valor según el tamaño deseado */
  vertical-align: middle; /* Alinea el ícono verticalmente con el texto */
  margin-left: 5px; /* Espacio entre el texto y el ícono */
}

 .texto-container2{
  padding: 20px;
    margin: 10px 0;
    opacity: 0;
    font-size: 20px;
    font-family: 'Dancing Script', sans-serif;
  font-weight: 350;    color: #333;
    border-radius: 10px;
    z-index: 2;
    width: 90%;
    margin-top:0;
    transform: translateY(50px);
 }
 .nombre-novioo{
  font-size: 28px;
    font-family: 'Dancing Script', sans-serif;
  font-weight: 350;    color: #333;

 }
  </style>
  