<template>
  <div>
    <!-- Contenedor Bienvenida -->
    <div
      ref="bienvenidaRef"
      class="bienvenida-container"
    >
      <p
        ref="textoRef"
        class="bienvenida-text"
      >
        ¡Que nos acompañes es lo más importante! Y si está en tu disposición realizar una muestra de cariño, estaremos muy agradecidos.
      </p>
    </div>
  </div>
</template>

<script>
import { onMounted, ref, nextTick } from 'vue';
import 'animate.css'; // Asegúrate de importar animate.css

export default {
  name: 'BienvenidaPage',
  setup() {
    const bienvenidaRef = ref(null);
    const textoRef = ref(null); // Referencia al texto
    const animationDone = ref(false); // Para controlar si la animación ya se ha hecho

    onMounted(async () => {
      await nextTick(); // Asegurarse de que el DOM esté completamente actualizado

      if (textoRef.value) {
        const element = textoRef.value;

        // Configuración del IntersectionObserver
        const observerOptions = {
          root: null, // Usar el viewport como raíz
          rootMargin: '0px', // Ajustar si es necesario
          threshold: [0.1] // Activar cuando el 10% del elemento sea visible
        };

        const observerCallback = (entries) => {
          entries.forEach(entry => {
            if (entry.isIntersecting) {
              if (!animationDone.value) {
                // Si el elemento es visible y la animación no ha sido realizada
                element.classList.add('animate__animated', 'animate__fadeInUp');
                element.addEventListener('animationend', () => {
                  element.classList.remove('animate__animated', 'animate__fadeInUp');
                  element.style.opacity = '1'; // Asegúrate de que el elemento se mantenga visible
                }, { once: true });

                // Marca la animación como realizada
                animationDone.value = true;
              }
            } else {
              // Si el elemento se vuelve invisible, resetea la animación
              element.style.opacity = '0'; // Ocultar el texto cuando no es visible
              // Marca la animación como no realizada
              animationDone.value = false;
            }
          });
        };

        // Crear un nuevo IntersectionObserver
        const observer = new IntersectionObserver(observerCallback, observerOptions);
        observer.observe(element);
      }
    });

    return { bienvenidaRef, textoRef };
  }
};
</script>

<style scoped>
/* Contenedor Bienvenida */
.bienvenida-container {
  position: relative;
  width: 100%;
  max-width: 800px;
  margin: 0 auto; /* Centra el contenedor horizontalmente */
  text-align: center;
  background: linear-gradient(to bottom, rgba(13, 13, 13, 0.073) 30%, rgba(41, 41, 41, 0.078)),
    url('../assets/textura-fondo.jpg'),
    linear-gradient(to top, rgba(13, 13, 13, 0.381) 30%, rgba(41, 41, 41, 0.078));
  background-size: cover; /* Asegura que la imagen cubra el contenedor */
  font-family: 'Dancing Script', cursive;
  padding: 20px 30px;
  display: flex;
  flex-direction: column; /* Apila los elementos verticalmente */
  align-items: center; /* Centra horizontalmente */
}

/* Texto de bienvenida */
.bienvenida-text {
  font-family: 'Dancing Script', cursive;
  font-size: 20px;
  font-weight: 800;
  letter-spacing: 0.2px;
  color: #1c1c1bb7 !important;
  opacity: 0; /* Empieza oculto */
  transition: opacity 0.3s ease; /* Transición suave de opacidad */
  white-space: pre-line;
}

/* Animación al entrar */
.animate__animated {
  animation-fill-mode: both; /* Mantiene el estado final de la animación */
}
</style>
