<template>
  <div class="full-width-image">
    <img
      src="../assets/fondo12.png"
      alt="Descripción de la imagen"
    >
    <div class="gradient-overlay" />

    <p class="texto" />
  </div>
</template>
  
  <script>
  export default {
    name: 'FullWidthImage',
  };
  </script>
  
  <style scoped>
  /* Elimina el margen y el padding del body para evitar barras de desplazamiento */
  body {
    margin: 0;

    padding: 0;
    overflow-x: hidden; /* Evita que aparezca la barra de desplazamiento horizontal */
  }
  
  /* Contenedor de la imagen */
  .full-width-image {

    position: relative; /* Asegura que la superposición se posicione correctamente */
    background-color: rgba(0, 0, 0, 0.7);  /* Fondo oscuro con transparencia (70%) */
    height: 490px;  /* Establece el alto fijo */

    width: 100%;
    overflow: hidden; /* Oculta cualquier contenido que exceda el contenedor */
  }
  
  .full-width-image img {
    width: 100%;
    height: auto; /* Mantiene la relación de aspecto de la imagen */
    display: block; /* Elimina el espacio debajo de la imagen */
  }


  @font-face {
  font-family: 'FeelingPassionate';
  src: url('@/assets/fonts/passionate.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: block; /* Espera a que la fuente esté cargada antes de mostrarla */
}

.texto {
  font-family: 'FeelingPassionate';
  font-size: 34px;
  opacity: 1; /* Asegura que el texto esté visible */
  color: #5f525d;
  margin-top: 10px;
  transition: opacity 0.3s ease;
}

.gradient-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, rgba(164, 154, 154, 0), rgba(0, 0, 0, 0.507));
}

  </style>
  