import { createApp } from 'vue';
import App from './App.vue';
import 'animate.css';
import 'aos/dist/aos.css';
import AOS from 'aos';
import './assets/main.css'; // Asegúrate de que la ruta sea correcta
import router from './router'; // Importa el enrutador que acabamos de configurar
import 'bootstrap/dist/css/bootstrap.min.css'; // Asegúrate de tener Bootstrap

const app = createApp(App);

// Inicializar AOS
app.mixin({
  mounted() {
    AOS.init({
      duration: 1000, // Duración de la animación en milisegundos
      once: false, // Si debe animar solo una vez
    });
  },
  updated() {
    AOS.refresh(); // Refresca AOS para asegurar que las animaciones se apliquen después de actualizar el DOM
  }
});

// Usar Vue Router
app.use(router);

// Montar la aplicación en el elemento con id 'app'
app.mount('#app');
