<template>
  <section
    id="itinerario"
    class="itinerario-section"
  >
    <div class="row">
      <div class="col-12 col-center">
        <div class="cuadro-body3">
          <div class="row">
            <div class="col-11 col-center">
              <h2 class="title text-white text-center">
                Itinerario <br>
              </h2>
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="col-12">
              <div class="timeline">
                <div 
                  v-for="evento in eventos"
                  :key="evento.nombre"
                  class="event d-flex align-items-center"
                  :data-nombre="evento.nombre"
                >
                  <div 
                    ref="iconoContainer" 
                    class="icon-container" 
                    :style="getEventStyle(evento)"
                  >
                    <!-- Solo se aplica el estilo al icono -->
                    <img 
                      :src="evento.icono" 
                      width="50" 
                      alt="" 
                      class="icono" 
                      :style="getIconStyle(evento)" 
                    >
                    <div class="line" />
                  </div>
                  <div class="ml-4 event-text">
                    <h3 class="title text-white">
                      {{ evento.nombre }}
                    </h3>
                    <p class="time text-white">
                      {{ evento.hora }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'ItinerarioCmd',
  data() {
    return {
      eventos: [
        { nombre: 'Ceremonia Religiosa', hora: '13:00 hrs - 14:00 hrs', icono: require('@/assets/iglesia-itinerario1.gif'), visible: false },
        { nombre: 'Recepción', hora: '17:00 hrs', icono: require('@/assets/recepcion_time.png'), visible: false },
        { nombre: 'Ceremonia Civil', hora: '17:15 hrs', icono: require('@/assets/ceremonia_time.png'), visible: false },
        { nombre: 'Entrada Novios', hora: '18:00 hrs', icono: require('@/assets/entradanovios_time.png'), visible: false },
        { nombre: 'Cena', hora: '19:00 hrs', icono: require('@/assets/cena_time.png'), visible: false },
        { nombre: 'Fiesta', hora: '20:00 hrs - 23:00 hrs', icono: require('@/assets/fiesta_time.png'), visible: false },
      ],
    };
  },
  mounted() {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          this.marcarComoVisible(entry);
        }
      });
    }, { threshold: 1});

    this.$nextTick(() => {
      const elementos = document.querySelectorAll('.event');
      elementos.forEach(el => observer.observe(el));
    });
  },
  methods: {
    // Estilo para el contenedor del evento
    getEventStyle(evento) {
      return evento.visible
        ? { backgroundColor: '#6c6757', borderColor: '#ffaa00' } // Estilos cuando el evento es visible
        : { backgroundColor: '#f3eadc', borderColor: '#a79683' }; // Estilos cuando el evento no es visible
    },
    // Estilo para el icono (cambia según la visibilidad)
    getIconStyle(evento) {
      return evento.visible
        ? { 
            filter: 'brightness(0.6)' // Oscurecer el icono con un filtro de brillo
          } 
        : {}; // No cambia el color del ícono cuando no es visible
    },
    marcarComoVisible(entry) {
      const evento = this.eventos.find(evento => evento.nombre === entry.target.dataset.nombre);
      if (evento && !evento.visible) {
        evento.visible = true;
      }
    },
  },
};
</script>

<style scoped>
.itinerario-section {
  padding: 20px;
  height: 800px;
  border-radius: 0 0 200px 200px;
  background: #2b2b2b;
}

.event-text .time {
  margin: 0;
  text-align: left;
  font-size: 0.9rem;
}

.event-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  margin-left: 15px;
  padding-left: 5px;
}

.event {
  position: relative;
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  font-size: 0.9rem;
}

.icon-container {
  height: 80px;
  width: 80px;
  background: #f3eadc;
  border-radius: 50%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.icon-container::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 70px;
  height: 70px;
  background-color: transparent;
  border: 4px solid #a79683;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  transition: border-color 0.3s ease;
}

.text-center{
  font-size: 38px;
  letter-spacing: 5px; /* Espaciado entre letras */
margin-bottom:20px;
  font-family: 'Great Vibes', cursive !important;
  font-weight: 800;
  margin: 0 30px;
}
</style>
