<template>
  <div class="music-player">
    <audio
      ref="audio"
      :src="songSource"
      preload="auto"
      @timeupdate="updateProgress"
      @ended="resetPlayer"
    >
      Your browser does not support the audio element.
    </audio>
  </div>
</template>

<script>
export default {
  data() {
    return {
      songTitle: 'Mi Canción Favorita',
      songSource: require('@/assets/musicafondo.mp3'), // Ruta corregida
      isPlaying: false,
      progress: 0,
    };
  },
  mounted() {
    // Intenta reproducir la música automáticamente
    this.playMusic();
  },
  methods: {
    playMusic() {
      this.$refs.audio.play();
      this.isPlaying = true;
    },
    pauseMusic() {
      this.$refs.audio.pause();
      this.isPlaying = false;
    },
    updateProgress() {
      const audio = this.$refs.audio;
      this.progress = (audio.currentTime / audio.duration) * 100;
    },
    seekMusic(event) {
      const audio = this.$refs.audio;
      const newTime = (event.target.value / 100) * audio.duration;
      audio.currentTime = newTime;
    },
    resetPlayer() {
      this.isPlaying = false;
      this.progress = 0;
    },
  },
};
</script>

<style scoped>
.music-player {
  width: 100%; /* Asegura que el componente ocupe todo el ancho */
  overflow: hidden; /* Evita el desbordamiento */
  display: none; /* Ocultar el componente */
}

/* O también puedes añadir esto al body en tu archivo CSS principal */
body {
  overflow-x: hidden; /* Oculta la barra de desplazamiento horizontal */
}
</style>
