import { createRouter, createWebHistory } from 'vue-router';
import App from './App.vue'; // Página principal
import Contenedor from './components/contenedor.vue';
import Login from './components/login_acceso.vue';

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/:nombre?/:idFestejado?/:idInvitado?', // Nombre, idFestejado e idInvitado son opcionales
    name: 'App',
    component: App,
    props: route => ({
      nombre: route.params.nombre || null, // Si no hay nombre, será null
      idFestejado: Number(route.params.idFestejado) || null, // Si no hay idFestejado, será null
      idInvitado: Number(route.params.idInvitado) || null, // Si no hay idInvitado, será null
    }),
    children: [
      {
        path: '',
        component: Contenedor,
        props: route => ({
          nombre: route.params.nombre || null,
          idFestejado: Number(route.params.idFestejado) || null,
          idInvitado: Number(route.params.idInvitado) || null,
        }),
      },
    ],
  },
];


const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
